/*
 * @Author: dingguowei
 * @Date: 2023-07-04 17:15:34
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-07-18 16:20:39
 */
// 自定义的指令

// 正整数
export const positiveInteger = {
        bind: function (el, binding, vnode) {
          el.addEventListener('input', function (event) {
            let inputValue = event.target.value;
      // 移除非数字字符
      inputValue = inputValue.replace(/\D/g, '');

      // 移除前导零
            inputValue = inputValue.replace(/^0+/, '');
            if (inputValue === '') { 
              inputValue=0
            }
      
      // 设置输入框的值为处理后的值
      event.target.value = inputValue;
          });
  
        },
        inserted: function (el, binding, vnode) {
          // 在元素插入到父节点时执行一些操作
        },
        update: function (el, binding, vnode, oldVnode) {
          // 在元素更新时执行一些操作
        },
        unbind: function (el, binding, vnode) {
          // 在解绑时执行一些操作
        }
};


// 正数并且小数点最多一位
export const positiveDecimal = {
  bind: function (el, binding, vnode) {
    el.addEventListener('input', function (event) {

      let inputValue = event.target.value;
      if (!inputValue) {
        inputValue = '0'
      }
      // 移除非数字和小数点之外的字符
      inputValue = inputValue.replace(/[^0-9.]/g, '');

      if (inputValue.charAt(0) === '.') {
        inputValue = '0' + inputValue;
        event.target.value = inputValue;
      } else if (inputValue.charAt(inputValue.length - 1) === '.') { 
        event.target.value = inputValue;
      } else { 
        event.target.value = (inputValue)
      }
   
           
   
    });

  },
  inserted: function (el, binding, vnode) {
    // 在元素插入到父节点时执行一些操作
  },
  update: function (el, binding, vnode, oldVnode) {
    // 在元素更新时执行一些操作
  },
  unbind: function (el, binding, vnode) {
    // 在解绑时执行一些操作
  }
};
  
// 下拉框
export const loadmore = {
  bind(el, binding) {
    // 获取element-ui定义好的scroll盒子
    const SELECTWRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
    SELECTWRAP_DOM.addEventListener('scroll', function () {
        /**
        * scrollHeight 获取元素内容高度(只读)
        * scrollTop 获取或者设置元素的偏移值,常用于, 计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚        动 条, 那它的scrollTop的值默认为0.
        * clientHeight 读取元素的可见高度(只读)
        * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
        * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
        */
        const condition = this.scrollHeight -1- this.scrollTop <= this.clientHeight;
      
        if (condition) {
            binding.value();
        }
    });
}
};

// 去除地址后面的参数
export const arrangeUrl = {
  bind: function (el, binding, vnode) {
    el.addEventListener('input', function (event) {
      let value
      if (event.target.value && event.target.value.includes('?')) {
        value = event.target.value.split('?')[0]
      } else { 
        value=event.target.value
      }
      event.target.value=value
    });

  },
  inserted: function (el, binding, vnode) {
    // 在元素插入到父节点时执行一些操作
  },
  update: function (el, binding, vnode, oldVnode) {
    // 在元素更新时执行一些操作
  },
  unbind: function (el, binding, vnode) {
    // 在解绑时执行一些操作
  }
};