/*
 * @Author: dingguowei
 * @Date: 2023-06-16 10:04:28
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-06-16 10:27:01
 */

/**
 *
 * @param {} value 输入的值
 * @returns 正整数
 */
export function positiveinteger(e) {
    e=e.toString()
    let value = e.replace(/[^\d]/g, ""); // 只能输入数字
    value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
    value = value.replace(/(\d{15})\d*/, '$1') // 最多保留15位整数
    return value
}