
/*
 * @Author: dingguowei
 * @Date: 2023-05-19 15:10:44
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-07-05 19:39:25
 */
import request from '@/plugins/axios'
const base = '/'

// 品牌方查询任务
export function brandGetTask(data) {
    return request({
      url:  base + 'api/zbMission/brand/page',
      headers: { // 修改请求头
        'Content-Type': 'application/json'
      },
      method: 'post',
      data
    })
}


// 首页任务详情
export function gettaskInfo(params) {
  return request({
    url:  base + 'api/zbMission/get',
    method: 'get',
    params
  })
}

// 首页任务详情查看任务下参与用户
export function getjoinuser(data) {
  return request({
    url:  base + 'api/zbMissionReceive/page',
    method: 'post',
    data
  })
}

// 首页任务详情查看任务下参与用户关闭任务操作
export function closejoinuser(data) {
  return request({
    url:  base + 'api/zbMissionReceive/close',
    method: 'post',
    data
  })
}


// 发布任务中图片上传接口
export function uploadimg(data) {
  return request({
    url:  base + 'api/cos/upload',
    method: 'post',
    data
  })
}

// 发布任务获取项目类别
export function getTaskList(params) {
  return request({
    url:  base + 'api/zbMissionType/list',
    method: 'get',
    params
  })
}

// 品牌方发布任务
export function saveTask(data) {
  return request({
    url:  base + 'api/zbMission/save',
    method: 'post',
    data
  })
}

// 品牌方修改任务
export function editTask(data) {
  return request({
    url:  base + 'api/zbMission/update',
    method: 'post',
    data
  })
}

// 品牌方上架任务
export function upTask(params) {
  return request({
    url:  base + 'api/zbMission/up',
    method: 'get',
    params
  })
}

// 品牌方下架任务
export function downTask(params) {
  return request({
    url:  base + 'api/zbMission/down',
    method: 'get',
    params
  })
}

// 品牌方关闭任务
export function closeTask(params) {
  return request({
    url:  base + 'api/zbMission/close',
    method: 'get',
    params
  })
}
/************************************ 内容管理**************************************************/

// 内容管理获取模板列表
export function getpromptList(params) {
  return request({
    url:  base + 'api/prompt/list',
    method: 'get',
    params
  })
}


// 内容管理根据id获取模板列表
export function getpromptListbyid(params) {
  return request({
    url:  base + 'api/zbMissionPromptRecord/prompt/list',
    method: 'get',
    params
  })
}


// 品牌方创建ai模板
export function saveprompt(data) {
  return request({
    url:  base + 'api/zbMissionPrompt/save',
    method: 'post',
    data
  })
}

export function peosaveprompt(data) {
  return request({
    url:  base + 'api/zbMissionPromptRecord/save',
    method: 'post',
    data
  })
}


//品牌方内容管理获取已发布的任务
export function getList(data) {
  return request({
    url:  base + 'api/zbMission/simple/page ',
    method: 'post',
    data
  })
}


// 品牌方内容管理获取所有的添加的模板
export function zbMissionPromptlist(data) {
  return request({
    url:  base + 'api/zbMissionPromptRecord/mission/page',
    method: 'post',
    data
  })
}


// 品牌方获取具体模板中的记录
export function zbMissionPromptRecord(data) {
  return request({
    url:  base + 'api/zbMissionPromptRecord/page',
    method: 'post',
    data
  })
}

// 品牌方修改模板记录
export function updatePromptRecord(data) {
  return request({
    url:  base + 'api/zbMissionPromptRecord/update',
    method: 'post',
    data
  })
}

// 品牌方批量删除模板内容
export function del(data) {
  return request({
    url:  base + 'api/zbMissionPromptRecord/del',
    method: 'post',
    data
  })
}

// 品牌方批量通过模板内容
export function batchUp(data) {
  return request({
    url:  base + 'api/zbMissionPromptRecord/batchUp',
    method: 'post',
    data
  })
}

// 品牌方批量分配模板内容
export function distribute(data) {
  return request({
    url:  base + 'api/zbMissionPromptRecord/batch/distribute',
    method: 'post',
    data
  })
}





// 品牌方任务看板查看任务领取情况（折线图）
export function dailylist(data) {
  return request({
    url:  base + 'api/back/daily/list',
    method: 'post',
    data
  })
}

// 品牌方任务看板查看笔记数据情况（柱状图）
export function annexlist(data) {
  return request({
    url:  base + 'api/back/daily/annex/list',
    method: 'post',
    data
  })
}



// 品牌方任务看板查看爆文互动统计
export function Articlestat(params) {
  return request({
    url:  base + 'api/back/interactionArticle/stat',
    method: 'get',
    params
  })
}

// 品牌方任务看板查看爆文互动统计
export function hotArticle(params) {
  return request({
    url:  base + 'api/back/hotArticle/stat',
    method: 'get',
    params
  })
}

// 品牌方任务看板查看排名
export function hotArticlerank(data) {
  return request({
    url:  base + 'api/back/hotArticle/page',
    method: 'post',
    data
  })
}


// 任务看板各种任务数据
export function receivestat(params) {
  return request({
    url:  base + 'api/back/receive/stat',
    method: 'get',
    params
  })
}

// 任务看板查看各项数据
export function backannex(params) {
  return request({
    url:  base + 'api/back/annex/stat',
    method: 'get',
    params
  })
}















