<!-- 内容管理页面 -->
<template>
  <div class="container">
    <div class="contentmgt">
      <el-row>
        <el-button size="mini" @click="aiDialogVisible = true"
          >AI生成</el-button
        >
        <el-button size="mini" @click="peoDialogVisible = true"
          >人工录入</el-button
        >
      </el-row>
      <el-table
        small
        :fit="true"
        ref="multipleTable"
        :data="tableData"
        :key="tableKey"
        style="
          width: calc(100% - 14px);
          font-size: 0.8rem;
          flex: 1;
          margin-top: 10px;
        "
        v-loading="loading"
        height="100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          label="序号"
          type="index"
          width="80"
          align="center"
          :show-overflow-tooltip="true"
        >
        </el-table-column>

        <el-table-column label="任务名称" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.missionTitle }}
          </template>
        </el-table-column>
        <el-table-column
          label="模板名称"
          width="120"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{ scope.row.promptName }}
          </template>
        </el-table-column>
        <el-table-column
          label="AI生成内容数量"
          width="140"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.aiAmount }}
          </template>
        </el-table-column>
        <el-table-column
          label="人工录入数量"
          width="140"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.manAmount }}
          </template>
        </el-table-column>
        <el-table-column
          label="AI已生成内容数量"
          width="160"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.alreadyAmount }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <span class="handel" @click="editcontent(scope.row)">查看详情</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        small
        class="pagination"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="getpromptcontent"
        :current-page.sync="pageNo"
        :page-size="pageSize"
      >
      </el-pagination>
    </div>
    <!-- ai生成弹窗 -->
    <el-dialog
      :close-on-click-modal="false"
      title="提示"
      :visible.sync="aiDialogVisible"
      width="30%"
      center
    >
      <div slot="title" class="header-title">
        <span class="name" style="color: black; font-size: 0.8rem"
          >新建内容</span
        >
      </div>
      <div>
        <el-form
          label-position="right"
          label-width="80px"
          :model="contentData"
          size="mini"
        >
          <el-form-item label="生成数量">
            <el-input
              size="mini"
              v-model="contentData.amount"
              placeholder="请输入生成数量"
              style="width: 10rem"
              :on-input="positiveinteger()"
            ></el-input>
          </el-form-item>
          <el-form-item label="选择任务">
            <el-select
              v-loadmore="loadMore"
              clearable
              filterable
              placeholder="请选择任务"
              remote
              style="width: 10rem"
              v-model="contentData.missionId"
            >
              <el-option
                :key="index"
                :label="item.title"
                :value="item.id"
                v-for="(item, index) in allprompts"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择模板">
            <el-select
              v-model="contentData.promptId"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in contentData.promptData"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="标题">
            <el-input
              v-model="contentData.title"
              type="textarea"
              :rows="2"
            ></el-input>
          </el-form-item>
          <el-form-item label="咨询内容">
            <el-input
              v-model="contentData.content"
              type="textarea"
              :rows="4"
            ></el-input>
          </el-form-item>
          <el-form-item label="评论植入">
            <el-input
              v-model="contentData.comment"
              type="textarea"
              :rows="2"
            ></el-input>
          </el-form-item>
          <el-form-item label="回复内容">
            <el-input
              v-model="contentData.reply"
              type="textarea"
              :rows="2"
            ></el-input>
          </el-form-item>
        </el-form>
        <el-row style="text-align: center">
          <el-button size="mini" @click="generate">立即生成 </el-button>
        </el-row>
      </div>
    </el-dialog>
    <!-- 人工录入弹窗 -->
    <el-dialog
      :close-on-click-modal="false"
      title="提示"
      :visible.sync="peoDialogVisible"
      width="30%"
      center
    >
      <div slot="title" class="header-title">
        <span class="name" style="color: black; font-size: 0.8rem"
          >人工录入</span
        >
      </div>
      <div :key="uploadKey">
        <el-form
          label-position="right"
          label-width="80px"
          :model="contentData"
          size="mini"
        >
          <el-form-item label="选择任务">
            <el-select
              v-loadmore="loadMore"
              clearable
              filterable
              placeholder="请选择任务"
              remote
              style="width: 10rem"
              v-model="peocontentData.missionId"
            >
              <el-option
                :key="index"
                :label="item.title"
                :value="item.id"
                v-for="(item, index) in allprompts"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="标题">
            <el-input
              v-model="peocontentData.title"
              type="textarea"
              :rows="2"
            ></el-input>
          </el-form-item>
          <el-form-item label="输入文案">
            <el-input
              v-model="peocontentData.response"
              type="textarea"
              :rows="4"
            ></el-input>
          </el-form-item>
          <el-form-item label="评论植入">
            <el-input
              v-model="peocontentData.comment"
              type="textarea"
              :rows="2"
            ></el-input>
          </el-form-item>
          <el-form-item label="回复内容">
            <el-input
              v-model="peocontentData.reply"
              type="textarea"
              :rows="2"
            ></el-input>
          </el-form-item>
          <el-form-item label="上传图片">
            <el-upload
              :class="{ hide: hideUploadAdd }"
              style="margin-top: 10px"
              size="mini"
              :limit="9"
              multiple
              action="/api/cos/upload"
              list-type="picture-card"
              :on-success="successChange"
              :on-change="handleAddChange"
              :on-preview="handlePictureCardPreview"
              :headers="uploadHeaders"
              :data="{ folder: 'record' }"
              :on-remove="handleRemove"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <!-- <el-dialog :visible.sync="dialogVisible">
                            <img width="100%" :src="dialogImageUrl" :preview-src-list="fileList" alt="" />
                        </el-dialog> -->
          </el-form-item>
        </el-form>
        <el-row style="text-align: center">
          <el-button size="mini" @click="peogenerate">立即生成 </el-button>
        </el-row>
      </div>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      title="提示"
      :visible.sync="contentDialogVisible"
      width="30%"
      center
    >
      <div slot="title" class="header-title">
        <span class="name" style="color: black; font-size: 0.8rem"
          >编辑内容</span
        >
      </div>
      <div>
        <el-input
          type="textarea"
          :rows="15"
          resize="none"
          class="response"
          v-model="editData.response"
        >
        </el-input>
        <el-row style="text-align: center; margin-top: 10px">
          <el-button size="mini" @click="submitContent(1)">确认修改</el-button>
          <el-button size="mini" @click="submitContent(2)">确认可用</el-button>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>
  
<script>
import router from "@/router";
import {
  getpromptList,
  saveprompt,
  peosaveprompt,
  zbMissionPromptlist,
  updatePromptRecord,
  del,
  batchUp,
  getList,
} from "@/api/home";
import store from "@/store";
import { positiveinteger } from "@/util/checkFun.js";
import { loadmore } from "@/config/directives";
export default {
  directives: {
    loadmore,
  },
  components: {},
  data() {
    return {
      aiDialogVisible: false,
      peoDialogVisible: false,
      contentDialogVisible: false,
      // 搜索
      promptId: null,
      status: null,
      promptData: [],
      //   模板内容
      contentData: {
        amount: 10,
        content: "",
        comment: "",
        reply: "",
        title: "",
        missionId: this.missionId,
        promptId: null,
        promptData: [],
      },
      peocontentData: {
        fileList: [],
        response: "",
        missionId: "",
        status: 2,
        comment: "",
        reply: "",
        title: "",
      },
      //   内容表格数据
      tableData: [],
      tableKey: false,
      pageNo: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      //   编辑弹窗数据
      editData: {
        id: null,
        response: "",
      },
      // 多选内容
      multipleSelection: [],
      // 所有模板
      allprompts: [],
      promptpageNo: 1,
      // 判断是否可以继续下拉数据
      alloadMore: true,
      // 上传图片相关
      hideUploadAdd: false,
      uploadHeaders: { "x-auth-token": localStorage.getItem("token") },
      uploadKey: true,
    };
  },
  created() {},
  async mounted() {
    await this.getpromptList();
    await this.getpromptcontent();
    await this.getList();
  },
  watch: {},
  computed: {
    missionId() {
      return store.state.task.taskInfo.missionId;
    },
  },
  methods: {
    // 获取所有模板列表
    async getpromptList() {
      await getpromptList().then((res) => {
        this.contentData.promptData = res.data;
      });
    },
    // 验证整数方法
    positiveinteger() {
      this.contentData.amount = positiveinteger(this.contentData.amount);
    },
    // 品牌方创建ai模板
    generate() {
      saveprompt({
        saveQoList: [
          {
            amount: this.contentData.amount,
            content: this.contentData.content,
            commentContent: this.contentData.reply,
            missionId: this.contentData.missionId,
            promptId: this.contentData.promptId,
            commentImplantation: this.contentData.comment,
            title: this.contentData.title,
          },
        ],
      }).then((res) => {
        if (res.code === 1000) {
          this.$message({
            message: "成功创建模板,请耐心等待生成内容",
            type: "success",
          });
          this.aiDialogVisible = false;
          this.getpromptcontent();
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    // 品牌方创建人工录入模板
    peogenerate() {
      peosaveprompt({
        imgUrlList: this.peocontentData.fileList,
        missionId: this.peocontentData.missionId,
        response: this.peocontentData.response,
        status: this.peocontentData.status,
        commentImplantation: this.peocontentData.comment,
        commentContent: this.peocontentData.reply,
        title: this.peocontentData.title,
      }).then((res) => {
        if (res.code === 1000) {
          this.$message({
            message: "人工录入成功",
            type: "success",
          });
          this.peoDialogVisible = false;
          this.clearcookie();
          this.getpromptcontent();
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    // 获取生成内容
    async getpromptcontent(val) {
      if (val === true) {
        this.pageNo = 1;
      }
      await zbMissionPromptlist({
        pageNo: this.pageNo,
        pageSize: 10,
      }).then((res) => {
        this.tableKey = !this.tableKey;
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
    // 编辑内容
    editcontent(val) {
      store.commit("task/CHANGE_MISSIONID", val.missionId);
      router.push({
        name: "内容管理详情",
      });
    },
    submitContent(STATUS) {
      updatePromptRecord({
        id: this.editData.id,
        response: this.editData.response,
        status: STATUS,
      }).then((res) => {
        if (res.code === 1000) {
          this.contentDialogVisible = false;
          this.getpromptcontent();
          this.$message({
            message: "修改成功",
            type: "success",
          });
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    // 多选
    handleSelectionChange(val) {
      this.multipleSelection = val.map((res) => {
        return res.id;
      });
    },
    // 批量操作
    async del() {
      if (this.multipleSelection.length === 0) {
        this.$message({
          message: "请选择批量删除模板",
          type: "warning",
        });
      } else {
        await del({ ids: this.multipleSelection }).then((res) => {
          if (res.code === 1000) {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.getpromptcontent();
          }
        });
      }
    },
    async batchUp() {
      if (this.multipleSelection.length === 0) {
        this.$message({
          message: "请选择批量通过模板",
          type: "warning",
        });
      } else {
        await batchUp({ ids: this.multipleSelection, status: 2 }).then(
          (res) => {
            if (res.code === 1000) {
              this.$message({
                message: "通过成功",
                type: "success",
              });
              this.getpromptcontent();
            }
          }
        );
      }
    },
    // 搜索内容管理新增内容中已有的任务
    async getList() {
      await getList({ pageNo: this.promptpageNo, pageSize: 20 }).then((res) => {
        if (res.data.records.length > 0) {
          this.allprompts = [...this.allprompts, ...res.data.records];
        } else {
          this.alloadMore = false;
        }
      });
    },
    // 加载更多
    loadMore() {
      if (this.alloadMore) {
        this.promptpageNo = this.promptpageNo + 1;
        this.getList();
      }
    },
    // 上传图片
    handleRemove(file, fileList) {
      this.peocontentData.fileList = this.peocontentData.fileList.filter(
        function (item) {
          return item !== file.response.data;
        }
      );

      if (fileList.length <= 9) {
        this.hideUploadAdd = false;
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 上传change事件
    handleAddChange(file, fileList) {
      // 图片大小限制
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.error("上传图片大小不能超过 20MB!");
        // 发现某一张超过大小就从列表移除
        fileList.splice(-1, 1);
      }
      // 上传文件>=限制个数时隐藏上传组件
      if (fileList.length >= 9) {
        this.hideUploadAdd = true;
      }
    },
    async successChange(response, file, fileList) {
      this.peocontentData.fileList.push(response.data);
    },
    // 清除缓存
    clearcookie() {
      this.uploadKey = !this.uploadKey;
      // 人工弹窗的内容
      (this.peocontentData.fileList = []), (this.peocontentData.response = "");
      this.peocontentData.missionId = "";
      // ai生成的内容
      this.contentData.content = "";
      this.contentData.promptId = null;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-form-item__label {
  font-size: 0.8rem;
}

.container {
  height: 100%;
  box-sizing: border-box;
  padding: 1rem 2rem;
  color: black;
  ::v-deep .el-icon-close:before {
    color: #000000;
  }
  .response {
    white-space: pre-wrap;
    font-size: 0.8rem;

    ::v-deep .el-textarea__inner {
      background-color: #f7f6f4;
    }
  }

  .status {
    margin-top: 10px;
    text-align: right;
    font-size: 0.8rem;
    color: #999999;

    & span {
      margin-right: 10px;
    }

    ::v-deep .el-radio__label {
      font-size: 0.8rem;
      color: #999999;
    }

    ::v-deep .el-radio__inner {
      width: 0.8rem;
      height: 0.8rem;
    }
  }
}

.contentmgt {
  height: 100%;
  display: flex;
  flex-direction: column;

  .search {
    float: right;
    font-size: 0.8rem;
  }

  .handel {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      color: #3f6fd7;
    }
  }

  .pagination {
    margin-top: 10px;
    margin-left: auto;
  }
}
</style>
<style lang="scss">
.el-dialog__body {
  max-height: 400px;
  overflow: auto;
}
</style>